import { EclipseContainer } from './container';
import { IEclipseConfig } from './interface/injection.interface';
import { Utils } from './utils';

export class EclipseWrapper extends HTMLElement {
  constructor() {
    super();
    const shadow = this.attachShadow({ mode: 'open' });
    const eclipse = document.createElement('eclipse-inject');
    // eclipse.setAttribute('config', JSON.stringify(window.eclipse.config));
    Utils.loadCSS(shadow, 'styles.css');
    shadow.appendChild(eclipse);
    Utils.loadJS(shadow, `polyfills.js`, 'module');
    Utils.loadJS(shadow, `main.js`, 'module');

    window.eclipse.config.assets.forEach(asset => {
      switch (asset.type) {
        case 'css': {
          Utils.loadCSS(shadow, asset.src);
          break;
        }
        case 'js': {
          Utils.loadJS(shadow, asset.src);
        }
      }
    });
    window.eclipseShadowRoot = shadow;
    window.addEventListener('update-color', (event: any) => {
      let element = shadow.querySelector('eclipse-inject') as HTMLElement;
      if (element && event?.detail?.data) {
        Utils.setColors(element, event.detail.data);
      }
      console.log('DATA', event);
      console.log('shadowRoot', shadow);
    });
  }
}

export class EclipseShadowDom extends EclipseContainer {
  constructor(eclipseConfig: IEclipseConfig) {
    super(eclipseConfig);
  }
  override init(): void {
    customElements.define('eclipse-shadow-wrapper', EclipseWrapper);
    const eclipse = document.createElement('eclipse-shadow-wrapper');
    const main = document.querySelector('main');
    this.containerWindow = window;
    this.containerWindow.injectionType = 'shadow';
    main?.prepend(eclipse);
    this.setComm();
    this.on('update-color', event => {
      this.containerWindow.dispatchEvent(new CustomEvent('update-color', {detail: event}))
    })
  }
}
