export class Utils {
  static loadJS(node: any, file: string, type: string = 'application/javascript') {
    const jsElm = document.createElement('script');
    jsElm.type = type;
    jsElm.src = file;
    node.appendChild(jsElm);
  }
  static loadCSS(node: any, file: string) {
    const cssElem = document.createElement('link');
    cssElem.rel = 'stylesheet';
    cssElem.href = file;
    node.prepend(cssElem);
  }
  static loadASync(FILE_URL: string,exist: string | null = null, async = true, type = 'text/javascript', ) {
    return new Promise((resolve, reject) => {
      try {
        const scriptEle = document.createElement('script');
        scriptEle.type = type;
        scriptEle.async = async;
        scriptEle.src = FILE_URL;

        scriptEle.addEventListener('load', ev => {
          resolve({ status: true });
        });

        scriptEle.addEventListener('error', ev => {
          reject({
            status: false,
            message: `Failed to load the script ＄{FILE_URL}`,
          });
        });
        if(exist && window.hasOwnProperty(exist)) {
          resolve({
            status: true
          })
        } else {
            document.body.appendChild(scriptEle);
        }
      } catch (error) {
        reject(error);
      }
    });
  }
  static removeAllChildNodes(parent: HTMLElement) {
    while (parent.firstChild) {
      parent.removeChild(parent.firstChild);
    }
  }

  static delay(ms: number) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve('');
      }, ms);
    });
  }
  static isEven(n: number) {
    return n % 2 == 0;
  }

  static isOdd(n: number) {
    return Math.abs(n % 2) == 1;
  }
  static setColors(container: HTMLElement, config: any) {
      if (config) {
        container?.style?.setProperty('--color-1', config.colors.color1);
        container?.style?.setProperty('--color-3', config.colors.color3); // Normal heading color
        container?.style?.setProperty('--color-2', config.colors.color2);
        container?.style?.setProperty('--color-4', config.colors.color4); // Normal text color
        container?.style?.setProperty('--color-5', config.colors.color5);
        container?.style?.setProperty('--color-6', config.colors.color6);
        container?.style?.setProperty('--color-7', config.colors.color7);
        container?.style?.setProperty('--color-8', config.colors.color8);
      }
  }
}
