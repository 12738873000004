import { IEventInfo } from "./interface/injection.interface";

export class EventService {
  eventList : {
    [key: string] : Array<(data: IEventInfo) => void>
  } = {};

  registerEvent(event: string, callBack: (data: IEventInfo) => void) : void {
    const eventInfo = this.eventList[event] ?? null;
    if (Array.isArray(eventInfo)) {
      this.eventList[event].push(callBack);
    } else {
      this.eventList[event] = [callBack];
    }
  }
  unRegisterEvent(event: string): void {
   delete this.eventList[event];
  }
  executeEvent(event: IEventInfo): void {
    this.eventList[event.type]?.forEach(callBack => callBack(event))
  }
}
