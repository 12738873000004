import { EclipseInjection } from '../script';

declare global {
  interface Window {
    eclipse: EclipseInjection;
    injectionType: string;
    _PMS : any;
    pid: string;
    pE: any;
    eclipseShadowRoot: any
  }
}

export interface IEclipseConfig {
  mode: 'iframe' | 'shadow';
  partnerId: string;
  assets: Array<{
    type: 'css' | 'js';
    src: string;
    loadingMode: 'async' | 'regular' | 'defer';
  }>;
  products: Array<string>;
  primaryProduct?: string;
  routerIdentifier: string; // NO URL, without identifier, with identifier
  routerEnabled: boolean;
}
export type IRequiredEclipseConfig = IEclipseConfig & {
  partnerId: string;
  products: Array<string>;
};
export interface IEventInfo<T = any> {
  type: string;
  data: T;
}
export const RouteModes = {
  PUSH: 'push',
  REPLACE: 'replace',
  NONE: 'none',
} as const;
export type RouteMode = (typeof RouteModes)[keyof typeof RouteModes];
export interface IRouteEvent {
  route: string,
  historyChange: RouteMode,
  reload: boolean,
}

export interface IEclipseContainerRef {
  on: (eventType: string, callBack: (event: IEventInfo) => void) => void;
  off: (eventType: string) => void;
  post: (message: IEventInfo) => void;
  setComm: (window: Window) => void;
}
