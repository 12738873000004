import { environment } from '../injection-v3/src/environments/environment';
import { EventService } from './event-service';
import {
  IEclipseConfig,
  IEclipseContainerRef,
  IEventInfo,
  IRouteEvent,
  RouteModes
} from './interface/injection.interface';
import { Utils } from './utils';

export class EclipseContainer implements IEclipseContainerRef {
  protected containerWindow!: Window;
  protected shadowRoot!: any;
  private eventService!: EventService;
  protected url!: string;
  protected v2ScriptUrl!: string;
  protected v2Mode!: string;
  constructor(protected eclipseConfig: IEclipseConfig) {
    this.eventService = new EventService();
    this.setUrl();
    this.init();
  }
  protected init(): void {
    // implement on child class
  }
  on<T>(eventType: string, callBack: (event: IEventInfo<T>) => void) {
    this.eventService.registerEvent(eventType, callBack);
  }
  post<T>(eventInfo: IEventInfo<T>) {
    setTimeout(() => {
      this.containerWindow.dispatchEvent(
        new CustomEvent('eclipse-parent-event', {
          detail: eventInfo,
        })
      );
    }, 0);
  }
  setComm(): void {
    this.containerWindow.addEventListener('eclipse-child-event', event => {
      const customEvent = event as CustomEvent<IEventInfo>;
      this.eventService.executeEvent(customEvent.detail);
    });
    this.on('child-init-success', event => {
      this.post({
        type: 'parent-init',
        data: {
          success: true,
          eclipseConfig: this.eclipseConfig,
        },
      });
      this.setRouter();
      this.setEvents();
    });
  }
  off(eventType: string) {
    this.eventService.unRegisterEvent(eventType);
  }
  resetInjection() {
    const main = document.querySelector('main');
    if (main) Utils.removeAllChildNodes(main);
  }
  setUrl() : void {
    let env = environment.ENV_MODE;
    let url = '';
    let v2ScriptUrl = '';
    let v2Mode = '';
    switch(env) {
      case 'local' : {
        url = '/';
        v2ScriptUrl = 'https://injectable.solar.com/prelive/pms.js';
        v2Mode = 'prelive';
        break;
      }
      case 'develop': {
        url = 'https://injectable.solar.com/prelive-v3/';
        v2ScriptUrl = 'https://injectable.solar.com/prelive/pms.js';
        v2Mode = 'prelive';
        break;
      }
      case 'staging' : {
        url = 'https://injectable.solar.com/uat-v3/';
        v2ScriptUrl = 'https://injectable.solar.com/uat/pms.js';
        v2Mode = 'uat';
        break;
      }
      case 'prod': {
        url = 'https://injectable.solar.com/v3/';
        v2ScriptUrl = 'https://injectable.solar.com/prod/pms.js';
        v2Mode = 'prod';
        break;
      }
      default : {
        url = '/';
        v2ScriptUrl = 'https://injectable.solar.com/prelive/pms.js';
        v2Mode = 'prod';
      }
    }
    this.url = url;
    this.v2ScriptUrl = v2ScriptUrl;
    this.v2Mode = v2Mode;
  }
  setRouter(): void {
    if(!this.eclipseConfig.routerEnabled) return;
    const parentUrl = window.location.origin + (
      window.location.pathname.search(`/${this.eclipseConfig.routerIdentifier}`) !== -1
      ? (window.location.pathname.split(this.eclipseConfig.routerIdentifier)[0] + this.eclipseConfig.routerIdentifier + '/') : '/').slice(0, -1);

    const onNativeRouteEvent = () => {
      if(window.eclipse.initV2) {
        window.location.reload();
        return;
      }
      this.post<IRouteEvent>({
        type: 'route-change',
        data: {
          route: window.location.href.split(parentUrl)[1],
          historyChange: 'replace',
          reload: false,
          reloadActiveRoute: false
        },
      });
      if(history.state && history.state['reload']) {
        history.replaceState(
          {id: window.location.href.split(parentUrl)[1], reload: false},
          '',
          window.location.href,
        );
        window.location.reload();
      }
    }
    window.onload = () => {
      onNativeRouteEvent();
    };
    window.onpopstate = () => {
      onNativeRouteEvent();
    }
    this.on<IRouteEvent>('route-change', e => {
      switch(e.data.historyChange) {
        case RouteModes.PUSH : {
          history.pushState(
            {id: e.data.route, reload: e.data.reload, prevUrl: window.location.href},
            '',
            parentUrl + e.data.route
          );
          break;
        }
        case RouteModes.REPLACE : {
          history.replaceState(
            {id: e.data.route, reload: e.data.reload},
            '',
            parentUrl + e.data.route
          );
          break;
        }
        case RouteModes.NONE : {
          //
          break;
        }
      }
      if(e.data.reloadActiveRoute) {
        window.location.reload();
      }
    });
  }
  setEvents(): void {
    // Event Analytics
    this.on('gtm-event', (data) => {
      window.dispatchEvent(
        new MessageEvent('message', {
          data: data.data,
        })
      );
    })
  }
}
