import { EclipseContainer } from './container';
import { IEclipseConfig } from './interface/injection.interface';
import { Utils } from './utils';

export class EclipseIFrame extends EclipseContainer {
  private eclipseSrc = `<html lang="en" data-critters-container=""><head>
    <meta charset="utf-8">
    <title>InjectionV3</title>
    <base href="$$__BASE_HREF__$$">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    </head><body><eclipse-inject></eclipse-inject></body></html>`;
  constructor(eclipseConfig: IEclipseConfig) {
    super(eclipseConfig);
    this.setIframeInjection();
  }

  initializeEclipseIFrame(node: HTMLElement) {
    node.style.height = 'inherit';
    node.style.width = 'inherit';
    const eclipseFrame = document.createElement('iframe') as HTMLIFrameElement;
    eclipseFrame.style.height = 'inherit';
    eclipseFrame.style.width = 'inherit';
    eclipseFrame.setAttribute('frameborder', '0');
    eclipseFrame.setAttribute('SameSite', 'none');
    eclipseFrame.id = 'eclipse-frame';
    node.appendChild(eclipseFrame);
    this.containerWindow = eclipseFrame.contentWindow ?? window;
    this.containerWindow.injectionType = 'iframe';
    this.containerWindow?.document.open();
    let newEclipseSource = this.eclipseSrc.replace("$$__BASE_HREF__$$", this.url);
    this.containerWindow?.document.write(newEclipseSource);
    Utils.loadCSS(this.containerWindow?.document.body, `${this.url}styles.css`);
    Utils.loadJS(this.containerWindow?.document.body, `${this.url}polyfills.js`, 'module');
    Utils.loadJS(this.containerWindow?.document.body, `${this.url}main.js`, 'module');
    window.eclipse.config.assets.forEach(asset => {
      switch (asset.type) {
        case 'css': {
          Utils.loadCSS(this.containerWindow?.document.body, asset.src);
          break;
        }
        case 'js': {
          Utils.loadJS(this.containerWindow?.document.body, asset.src);
        }
      }
    });
    this.containerWindow?.document.close();
  }
  setIframeInjection() {
    const eclipse = document.createElement('eclipse-wrapper');
    // eclipse.setAttribute('config', JSON.stringify(this.eclipseConfig));
    const main = document.querySelector('main');
    main?.prepend(eclipse);
    this.initializeEclipseIFrame(eclipse);
    this.setComm();
  }
}
